import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import '../scss/index.scss'
import Layout from '../components/layout/Layout'
import NewSliderBlock from '../components/NewSliderBlock'
import TextBlock from '../components/TextBlock'
import SEO from '../components/Seo'
import smoothscroll from 'smoothscroll-polyfill'

const IndexPage = ({ data }) => {
  const _data = data.markdownRemark.frontmatter

  useEffect(() => {
    smoothscroll.polyfill()
  }, [])

  // *************************************
  // ** THIS useEffect TURNS SCALING ON **
  // *************************************
  useEffect(() => {
    const htmlEl = document.getElementsByTagName('html')
    htmlEl[0].style.fontSize = `${window.innerWidth * (window.innerWidth < 576 ? 0.0745 : 0.022)}px`
    const textSection = document.getElementById('text-section')
    textSection.style.height = `${window.outerHeight - 102}px`

    window.addEventListener('resize', () => {
      htmlEl[0].style.fontSize = `${
        window.innerWidth * (window.innerWidth < 576 ? 0.0745 : 0.022)
      }px`
      const textSection = document.getElementById('text-section')
      textSection.style.height = `${window.outerHeight - 102}px`
    })
    return () => {
      window.removeEventListener('resize', () => (htmlEl[0].style.fontSize = '28px'))
    }
  }, [])

  return (
    <Layout logo={_data.logoText} title={_data.title}>
      <SEO description={data.site.siteMetadata.description} title={data.site.siteMetadata.title} />
      <NewSliderBlock data={_data.sliders} isTemlate={false} />
      <TextBlock top={_data.topColumn} mid={_data.midColumn} bot={_data.botColumn} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
    markdownRemark(fileAbsolutePath: { regex: "/homepage.md/" }) {
      frontmatter {
        title
        logoText
        topColumn
        midColumn
        botColumn
        slug
        sliders {
          title
          pics {
            childImageSharp {
              fluid(
                quality: 100
                maxWidth: 700
                maxHeight: 480
                traceSVG: { color: "#ffffff" }
                srcSetBreakpoints: [350, 700, 1400]
                fit: COVER
              ) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
