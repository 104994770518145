import React from 'react'
import Img from 'gatsby-image'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay, Navigation } from 'swiper/core'

SwiperCore.use([Autoplay, Navigation])

const getSlider = (data, index, isTemplate) => {
  const options = {
    containerModifierClass: `swiper-container swiper-container-${index % 2 ? 'three' : 'two'} `,
    speed: 1500,
    slidesPerView: 2,
    loopAdditionalSlides: 2,
    observer: true,
    breakpoints: {
      // when window width is >= 575px
      575: {
        slidesPerView: index % 2 ? 3 : 2,
        loopAdditionalSlides: index % 2 ? 3 : 2,
      },
    },
    navigation: {
      nextEl: `.nav-button-next-${index}`,
      prevEl: `.nav-button-prev-${index}`,
    },
    edgeSwipeDetection: true,
    preloadImages: true,
    updateOnImagesReady: true,
    updateOnWindowResize: true,
    resistance: true,
    touchReleaseOnEdges: true,
    loop: true,
  }
  return (
    <div className='slider-container' key={index}>
      <Swiper {...options}>
        {data.pics.map((pic, p) => (
          <SwiperSlide key={p}>
            {isTemplate ? (
              <img className='item-img' key={p} src={pic} alt='' />
            ) : (
              <Img loading={'eager'} key={p} fluid={pic.childImageSharp.fluid} />
            )}
          </SwiperSlide>
        ))}
      </Swiper>
      <div className='nav-buttons-container'>
        <div className={`nav-button nav-button-prev nav-button-prev-${index}`}>
          <div className='arrow arrow-prev' />
        </div>
        <div className={`nav-button nav-button-next nav-button-next-${index}`}>
          <div className='arrow arrow-next' />
        </div>
      </div>
      <div className='slider-title'>
        <p>{data.title}</p>
      </div>
    </div>
  )
}
const SliderBlock = ({ data, isTemplate }) => {
  return (
    <section className='slider-section'>
      {data.map((slider, s) => getSlider(slider, s, isTemplate))}
    </section>
  )
}

export default SliderBlock
