import React, { useState, useEffect } from 'react'
import remark from 'remark'
import recommended from 'remark-preset-lint-recommended'
import _html from 'remark-html'

const getHtml = async (md) => {
  const htmlOut = await remark().use(recommended).use(_html).process(md)
  return htmlOut.contents
}

const TextBlock = ({ top, mid, bot }) => {
  const [topText, setTopText] = useState('')
  const [midText, setMidText] = useState('')
  const [botText, setBotText] = useState('')

  useEffect(() => {
    async function getContent() {
      setTopText(await getHtml(top))
      setMidText(await getHtml(mid))
      setBotText(await getHtml(bot))
    }
    getContent()
  }, [top, mid, bot])

  return (
    <>
      <section id='text-section' className='text-section'>
        <div className='inner'>
          <div className='column column-top' dangerouslySetInnerHTML={{ __html: topText }} />
          <div className='column column-mid' dangerouslySetInnerHTML={{ __html: midText }} />
          <div
            id='about'
            className='column column-bottom'
            dangerouslySetInnerHTML={{ __html: botText }}
          />
        </div>
      </section>
    </>
  )
}

export default TextBlock
